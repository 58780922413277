import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import SuspenseView from '../../suspense-view';

import AddProviderDialog from '../../dialogs/add-provider';
import ProviderListView from './provider-list-view';

import { getProviderList } from '../../../api/provider';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const useFetch = (lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderList();
      if (success) {
        await setData(data);
      } else {
        await setError(errorMessage);
      }
    };

    fetchData();
  }, [lastUpdated]);

  return { data, error };
};

export default function ProviderManagementPage() {
  const classes = useStyles();
  const { t, ready } = useTranslation(['common', 'provider']);

  const [showProviderDialog, setShowProviderDialog] = useState(false);

  const [providerListState, setProviderListState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setProviderListState((providerListState) => ({
      ...providerListState,
      lastUpdated: moment(),
    }));
  };

  // ADD Dialog Methods
  const openAddProviderDialog = () => {
    setShowProviderDialog(true);
  };

  const closeAddProviderDialog = () => {
    setShowProviderDialog(false);
  };

  // Retrieve the bike list data
  const response = useFetch(providerListState.lastUpdated);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const responseData = response.data;
  let numberOfProviders = _.get(responseData, 'length', 0);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.providersLabel}>
        <Typography variant='h6' color='primary'>
          {t('provider:lblNumProviders', {
            num: numberOfProviders,
          })}
        </Typography>
      </div>
      <div className={classes.buttonDiv}>
        <Link to={'/dashboard'}>{t('common:back')}</Link>
        <Button
          className={classes.addButton}
          color='primary'
          variant='contained'
          startIcon={<AddIcon />}
          onClick={openAddProviderDialog}
        >
          {t('provider:lblAddProvider')}
        </Button>
        <div hidden>
          <AddProviderDialog
            show={showProviderDialog}
            handleClose={closeAddProviderDialog}
            setLastUpdated={setLastUpdated}
          />
        </div>
      </div>
      <br />
      <ProviderListView providers={responseData} />
    </div>
  );
}
