import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import formClasses from '../../components/common/form/form.module.scss';
import classNames from 'classnames';

import TextField from '../../components/common/form/texField/textField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import useStyles from './styles';

export default function SignUpForm({ t }) {
  const classes = useStyles();
  const { goToPage, signUp } = useAuthDataContext();

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  const requiredTranslated = t('form:required');

  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    password: Yup.string()
      .required(requiredTranslated)
      .min(8, t('form:minimumLength', { value: 8 })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('form:passwordsDoNotMatch'))
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    const result = await signUp(values);
    if (result.success) {
      goToPage(`/signup/${values.email}`);
    } else {
      let errorMessage;
      if (result.awsErrorCode === 'UsernameExistsException') {
        errorMessage = t('authentication:errorEmailAlreadyInUse', {
          email: values.email,
        });
      } else {
        errorMessage = t(result.errorMessage);
      }

      actions.setStatus({ msg: errorMessage });
    }
  };

  return (
    <div className={formClasses.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, isValid }) => (
          <Form
            className={classNames(formClasses.paper, formClasses.paperSmall)}
          >
            <div className={formClasses.title}>
              {t('authentication:signUp')}
            </div>
            <Field
              name='email'
              required
              component={TextField}
              placeholder={t('authentication:email')}
            />
            <Field
              name='password'
              required
              component={TextField}
              placeholder={t('authentication:password')}
              type='password'
            />
            <Field
              name='confirmPassword'
              component={TextField}
              placeholder={t('authentication:confirmPassword')}
              type='password'
            />

            {status && status.msg && (
              <Typography className={classes.error}>{status.msg}</Typography>
            )}

            <Button
              className={classes.submitButton}
              variant='contained'
              color='secondary'
              type='submit'
              disabled={!isValid || isSubmitting}
            >
              {t('form:submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

SignUpForm.propTypes = {
  t: PropTypes.func,
};
