import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';

import { LANGUAGE_NAMES } from './constants';

import { useTranslation } from 'react-i18next';
import { changeLanguage, DEFAULT_LNG } from '../../../i18n/i18n';
import { useStyles } from './styles';

export default function LanguageSelect() {
  const [languageMenu, setLanguageMenu] = React.useState(null);
  const [userLanguage, setUserLanguage] = React.useState(DEFAULT_LNG);

  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleMenu = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleClose = () => {
    setLanguageMenu(null);
  };

  return (
    <>
      <div>
        <Tooltip title={t('common:lblChangeLanguage')} enterDelay={300}>
          <Button
            color='inherit'
            aria-owns={languageMenu ? 'language-menu' : undefined}
            aria-haspopup='true'
            aria-label={t('common:lblChangeLanguage')}
            onClick={handleMenu}
            data-ga-event-category='header'
            data-ga-event-action='language'
          >
            <LanguageOutlinedIcon />
            <span className={classes.language}>
              {
                LANGUAGE_NAMES.filter(
                  (language) => language.code === userLanguage
                )[0].label
              }
            </span>
            <ExpandMoreIcon fontSize='small' />
          </Button>
        </Tooltip>
        <Menu
          id='language-menu'
          elevation={0}
          anchorEl={languageMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(languageMenu)}
          onClose={handleClose}
        >
          {LANGUAGE_NAMES.map((language) => (
            <MenuItem
              component='a'
              data-no-link='true'
              key={language.code}
              selected={userLanguage === language.code}
              onClick={() => {
                setUserLanguage(language.code);
                changeLanguage(language.code);
                handleClose();
              }}
            >
              {language.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
}
