import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import DashboardPage from '../../components/pages/dashboard';
import HomePage from '../../components/pages/home';
import ProviderManagementPage from '../../components/pages/providerManagement';

function Router() {
  return (
    <Routes>
      <Route exact path='/' element={<HomePage />} />
      <Route exact path='/dashboard' element={<PrivateRoute/>}>
          <Route exact path='/dashboard' element={<DashboardPage />}/>
      </Route>
      <Route exact path='/provider-management' element={<PrivateRoute/>}>
          <Route exact path='/provider-management' element={<ProviderManagementPage />}/>
      </Route>
    </Routes>
  );
}

export default Router;
