import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';

import ProviderListItemView from '../provider-list-item-view';

import useStyles from './styles';

export default function ProviderListView({ providers }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <List className={classes.list}>
          {providers.map((entry) => (
            <ProviderListItemView key={entry.id} details={entry} />
          ))}
        </List>
      </div>
    </div>
  );
}

ProviderListView.propTypes = {
  providers: PropTypes.array.isRequired,
};
