import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Route, Routes } from 'react-router';
import AuthApi from './api';

import ForgotPasswordPage from './forgot-password';
import ResetPasswordPage from './reset-password';
import SetPasswordPage from './set-password';
import SignInPage from './signin';
import SignUpPage from './signup';

import { useTranslation } from 'react-i18next';

const AuthDataContext = React.createContext([{}, () => {}]);

const AuthDataProvider = (props) => {
  const { t } = useTranslation('common');

  const [state, setState] = useState({
    isInitialized: false,
    isSignedIn: false,
    user: null,
    landingPage: '/',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await AuthApi.currentAuthenticatedUser();
      setState((state) => ({
        ...state,
        user: user.error ? null : user,
        isInitialized: true,
      }));
    };

    fetchUserData();
  }, []);

  if (!state.isInitialized) {
    return <div>{t('common:lblLoading')}</div>;
  }

  return (
    <AuthDataContext.Provider value={[state, setState]}>
      {props.children}
      {!state.isSignedIn && (
        <Routes>
          <Route path='signin' element={<SignInPage/>}>
            <Route path=':email' element={<SignInPage />} />
          </Route>
          <Route path='signup' element={<SignUpPage/>}>
            <Route path=':confirmEmail' element={<SignUpPage />} />
          </Route>
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route path='reset-password' element={<ResetPasswordPage/>}>
            <Route path=':confirmEmail' element={<ResetPasswordPage />} />
          </Route>
          <Route path='set-password' element={<SetPasswordPage/>}>
            <Route path=':confirmEmail' element={<SetPasswordPage />} />
          </Route>
        </Routes>
      )}
    </AuthDataContext.Provider>
  );
};

AuthDataProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthDataContext, AuthDataProvider };
