import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

export default useStyles;
