import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import LanguageSelect from './language-select';

import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const SignedInControls = ({ t, signOut }) => {
  return (
    <>
      <Button onClick={signOut}>{t('authentication:signOut')}</Button>
    </>
  );
};

SignedInControls.propTypes = {
  t: PropTypes.func,
  signOut: PropTypes.func,
};

function Header() {
  const { isSignedIn, signOut } = useAuthDataContext();
  const { t } = useTranslation(['common', 'authentication']);
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.header}>
      <span className={classes.space} />

      <div className={classes.grow} />

      <Divider orientation='vertical' flexItem />
      <LanguageSelect />

      <div>
        {isSignedIn ? (
          <SignedInControls t={t} signOut={signOut} />
        ) : (
          <Button onClick={() => navigate('/signin')}>
            {t('authentication:signIn')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;
