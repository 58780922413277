import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

export default function HomePage() {
  const { t } = useTranslation('common');

  return (
    <>
      <br />
      <Typography variant={'h4'} gutterBottom>
        {t('common:lblWelcomeMessagePartOne')}
      </Typography>
      <br />
      <Typography variant={'h5'} gutterBottom>
        {t('common:lblWelcomeMessagePartTwo')}
      </Typography>
    </>
  );
}
