import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthApi from '../api';
import { AuthDataContext } from '../AuthDataContext';

const useAuthDataContext = () => {
  const [state, setState] = useContext(AuthDataContext);
  const navigate = useNavigate();

  const signIn = async (username, password) => {
    const user = await AuthApi.signIn(username, password);
    await setUser(user);
    return user;
  };

  const signOut = async () => {
    const response = await AuthApi.signOut();
    await setUser(null);
    goToPage('/');
    return response;
  };

  const signUp = async (username, password, attributes = {}) =>
    AuthApi.signUp(username, password, attributes);

  const confirmSignUp = async (username, code) =>
    AuthApi.confirmSignUp(username, code);

  const resendSignUp = async (username) => AuthApi.resendSignUp(username);

  const resetPassword = async (username) =>
    AuthApi.resetPassword(username);

  const confirmResetPassword = async (username, code, password) =>
    AuthApi.confirmResetPassword(username, code, password);

  const completeNewPassword = async (newPassword) =>
    await AuthApi.completeNewPassword(state.user, newPassword);

  const goToPage = (page) => navigate(page ? page : state.landingPage, { replace: true });

  const setUserState = (user) =>
    setState((state) => ({
      ...state,
      user: user && user.data,
      isSignedIn: user && user.success,
    }));

  const setUser = async (user) => {
    // User must change password
    if (user && user.challengeName) {
      // Set the user so it can be used later
      setState((state) => ({
        ...state,
        user: user,
      }));

      return;
    }

    setUserState(user);

    // Read in the additional user information from other sources (i.e. database)
    /*if (user && user.success) {
      const result = await getUserDetails(user.data.sub);
      setUserState(result);
    } else {
      setUserState(user);
    }*/
  };

  return {
    isSignedIn: state.isSignedIn,
    user: state.user,
    signIn,
    signOut,
    signUp,
    goToPage,
    confirmSignUp,
    resendSignUp,
    resetPassword,
    confirmResetPassword,
    completeNewPassword,
  };
};

export default useAuthDataContext;
