import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 425,
    overflow: 'auto',
    margin: theme.spacing(0, 0.5, 0, 2),
  },
  list: {
    width: '100%',
    maxWidth: '125ch',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
