import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';

import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import useStyles from './styles';

export default function DashboardPage() {
  const { t } = useTranslation(['common']);
  const { goToPage } = useAuthDataContext();
  const classes = useStyles();

  function handleClickProviders() {
    goToPage('/provider-management');
  }

  let providersText = t('common:lblProviderManagement');

  return (
    <div className={classes.root}>
      <Typography variant='h4'>{t('common:lblOptions')}</Typography>
      <br />
      <div className={classes.listDiv}>
        <List className={classes.list}>
          <ListItem button key={providersText} onClick={handleClickProviders}>
            <ListItemIcon edge='start'>
              <BusinessRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={providersText} />
          </ListItem>
        </List>
      </div>
    </div>
  );
}
