import { awsApiRequest } from '../utils/func';
import { unmaskPhoneNumber } from '../../utils/numberUtils';

export async function getProviderList() {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/admin/providers`,
    });
  } catch (error) {
    return error;
  }
}

export async function addProvider(values) {
  try {
    const body = {
      name: values.name,
      description: values.description,
      website: values.website,
      contact_email: values.companyEmail,
      contact_number: unmaskPhoneNumber(values.companyContactNumber),
      admin_details: {
        first_name: values.adminFirstName,
        last_name: values.adminLastName,
        email: values.adminEmail,
        contact_number: unmaskPhoneNumber(values.adminContactNumber),
      },
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/admin/providers`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}
