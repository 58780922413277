import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 25,
  },
  providersLabel: {
    margin: theme.spacing(0, 0.5, 0, 2),
    float: 'left',
  },
  buttonDiv: {
    margin: theme.spacing(0, 3, 0, 0.5),
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  addButton: {
    textTransform: 'none',
  },
}));

export default useStyles;
