import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'

import PhoneNumberMask from '../../common/form/phone-number-mask';
import SuspenseView from '../../suspense-view';

import { addProvider } from '../../../api/provider';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';


export default function AddProviderDialog({
  show,
  handleClose,
  setLastUpdated,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t, ready } = useTranslation(['common', 'error', 'form', 'provider']);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  const dropdownOptions = [
    { key: 'Option 1', value: 'Funeral / End of Life Services' },
    { key: 'Option 2', value: 'Medical Equipment Provider' },
    { key: 'Option 3', value: 'Home Support Services' },
    { key: 'Option 4', value: 'Mental Health' },
  ]

  const initialValues = {
    name: '',
    description: '',
    website: '',
    companyEmail: '',
    companyType: '',
    companyContactNumber: '',
    adminEmail: '',
    adminFirstName: '',
    adminLastName: '',
    adminContactNumber: '',
  };

  const requiredTranslated = t('form:required');
  const AddProviderSchema = Yup.object().shape({
    // Company detail fields
    name: Yup.string().required(requiredTranslated),
    description: Yup.string().notRequired(),
    website: Yup.string().notRequired(),
    companyType: Yup.string().notRequired(),
    companyContactNumber: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(requiredTranslated),
    companyEmail: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),

    // Administrator details fields
    adminEmail: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    adminFirstName: Yup.string().required(requiredTranslated),
    adminLastName: Yup.string().required(requiredTranslated),
    adminContactNumber: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      const result = await addProvider(values);
      if (result.success) {
        handleClose();
        setLastUpdated();
      } else {
        let errorMessage;
        if (result.statusCode === 409) {
          let key = Object.keys(result.errorData.messages)[0];
          if (key === 'name') {
            errorMessage = t('provider:errorProviderAlreadyInUse', {
              name: values.name,
            });
          } else {
            errorMessage = t('provider:errorEmailAlreadyInUse', {
              email: values.adminEmail,
            });
          }
        } else {
          errorMessage = t(result.errorMessage);
        }

        actions.setStatus({ msg: errorMessage });
      }
    } else {
      setLastUpdated();
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='add-provider-dialog-title'
      >
        <DialogTitle id='add-provider-dialog-title'>
          {t('provider:lblAddProvider')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={AddProviderSchema}
          >
            {({
              errors,
              touched,
              values,
              status,
              handleChange,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <InputLabel>{t('provider:lblCompanyDetails')}</InputLabel>
                <br />
                <Field
                  className={classes.textField}
                  type='text'
                  name='name'
                  id='name'
                  label={t('provider:lblName')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}

                />
                {errors.name && touched.name ? (
                  <div className={classes.error}>{errors.name}</div>
                ) : null}
                <Field
                  className={classes.textField}
                  type='text'
                  id='companyContactNumber'
                  name='companyContactNumber'
                  label={t('provider:lblPhoneNumber')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.companyContactNumber}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: PhoneNumberMask }}
                />
                {errors.companyContactNumber && touched.companyContactNumber ? (
                  <div className={classes.error}>
                    {errors.companyContactNumber}
                  </div>
                ) : null}
                <Field
                  className={classes.textField}
                  type='text'
                  id='companyEmail'
                  name='companyEmail'
                  label={t('provider:lblEmail')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.companyEmail}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  className={classes.textField}
                  as='select'
                  id='companyType'
                  name='companyType'
                  label={'Type'}
                  margin='normal'
                  variant='outlined'
                  component={Select}
                  value={values.companyType}
                  onChange={handleChange}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                >
                  {dropdownOptions.map((option) => (
                    <MenuItem name={option.key} value={option.value} key={option.key}>{option.value}</MenuItem>
                  ))}
                </Field>


                {errors.companyEmail && touched.companyEmail ? (
                  <div className={classes.error}>{errors.companyEmail}</div>
                ) : null}
                <Field
                  className={classes.textField}
                  type='text'
                  id='website'
                  name='website'
                  label={t('provider:lblWebsite')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.website}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.website && touched.website ? (
                  <div className={classes.error}>{errors.website}</div>
                ) : null}
                <Field
                  multiline
                  rows={3}
                  className={classes.textField}
                  type='text'
                  id='description'
                  name='description'
                  label={t('provider:lblDescription')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.description}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.description && touched.description ? (
                  <div className={classes.error}>{errors.description}</div>
                ) : null}

                <br />
                <br />
                <InputLabel>{t('provider:lblAdministratorDetails')}</InputLabel>
                <br />

                <Field
                  className={classes.textField}
                  type='text'
                  id='adminEmail'
                  name='adminEmail'
                  label={t('provider:lblEmail')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.adminEmail}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.adminEmail && touched.adminEmail ? (
                  <div className={classes.error}>{errors.adminEmail}</div>
                ) : null}

                <Field
                  className={classes.textField}
                  type='text'
                  name='adminFirstName'
                  id='adminFirstName'
                  label={t('provider:lblAdminFirstName')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.adminFirstName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.adminFirstName && touched.adminFirstName ? (
                  <div className={classes.error}>{errors.adminFirstName}</div>
                ) : null}
                <Field
                  className={classes.textField}
                  type='text'
                  name='adminLastName'
                  id='adminLastName'
                  label={t('provider:lblAdminLastName')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.adminLastName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.adminLastName && touched.adminLastName ? (
                  <div className={classes.error}>{errors.adminLastName}</div>
                ) : null}
                <Field
                  className={classes.textField}
                  type='text'
                  id='adminContactNumber'
                  name='adminContactNumber'
                  label={t('provider:lblPhoneNumber')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.adminContactNumber}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: PhoneNumberMask }}
                />
                {errors.adminContactNumber && touched.adminContactNumber ? (
                  <div className={classes.error}>
                    {errors.adminContactNumber}
                  </div>
                ) : null}

                {status && status.msg && (
                  <Typography className={classes.error}>
                    {status.msg}
                  </Typography>
                )}

                <Divider />

                <Grid container justify='flex-end'>
                  <Button
                    variant='text'
                    color='inherit'
                    onClick={async () => {
                      values = {
                        isCancelButton: true,
                        isDirty: dirty,
                      };
                      onSubmit(values, {});
                    }}
                    aria-label='Cancel'
                  >
                    {t('form:cancel')}
                  </Button>
                  <Dialog
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent>{t('form:discardChanges')}</DialogContent>
                    <DialogActions>
                      <Button
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={!dirty || isSubmitting}
                  >
                    {t('form:submit')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddProviderDialog.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  setLastUpdated: PropTypes.func,
};
