import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';

import SuspenseView from '../../../suspense-view';

import { formatPhoneNumber } from '../../../../utils/numberUtils';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export default function ProviderListItemView({ details }) {
  const { t, ready } = useTranslation(['common', 'provider']);
  const classes = useStyles();

  // Set the local attributes
  let providerId = details.id;
  let name = details.name;
  let description = details.description;
  let website = details.website;
  let email = details.contact_email;
  let phone = formatPhoneNumber(details.contact_number);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div>
      <ListItem key={providerId} disableGutters={true}>
        <ListItemIcon edge='start' className={classes.listItemIcon}>
          <DescriptionRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant='h6'>{name}</Typography>}
          secondary={
            <>
              {description ? (
                <>
                  <span className={classes.listItemSecondaryText}>
                    <Typography
                      component='span'
                      variant='subtitle1'
                      style={{ color: 'black' }}
                    >
                      {description}
                    </Typography>
                  </span>
                  <br />
                </>
              ) : null}
              <span className={classes.listItemSecondaryText}>
                {website ? (
                  <Typography
                    component='span'
                    variant='subtitle1'
                    style={{ color: 'grey' }}
                  >
                    {t('provider:lblWebsite') + ': ' + website}
                  </Typography>
                ) : null}
                <Typography
                  component='span'
                  variant='subtitle1'
                  style={{ color: 'grey' }}
                >
                  {t('provider:lblEmail') + ': ' + email}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle1'
                  style={{ color: 'grey' }}
                >
                  {t('provider:lblPhoneNumber') + ': ' + phone}
                </Typography>
              </span>
            </>
          }
        />
      </ListItem>
      <Divider variant='inset' component='li' />
    </div>
  );
}

ProviderListItemView.propTypes = {
  details: PropTypes.object.isRequired,
};
